
























































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchBotlogReport } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";

@Component({
  components: {
    Apply,
    JsonCSV,
    AdminViewUser,
    EditUser,
    UploadUserDoc,
    EditAddress,
  },
})
export default class SalesReport extends Vue {
  public dialog = false;

  public onlyAdmin = false;
  public onlyPaid = false;

  public headers() {
    return [
      {
        text: "Email",
        sortable: true,
        value: "email",
        align: "left",
      },
      {
        text: "Message",
        value: "message",
        align: "left",
      },
      {
        text: "Created Date",
        sortable: true,
        value: "created_date",
        align: "left",
      },
      {
        text: "Updated Date",
        sortable: true,
        value: "updated_date",
        align: "left",
      },
      {
        text: "Session Time",
        sortable: true,
        value: "session_datetime",
        align: "left",
      },
      {
        text: "Session",
        sortable: true,
        value: "session",
        align: "left",
      },
      {
        text: "Token",
        sortable: true,
        value: "token",
        align: "left",
      },
    ];
  }

  public search = "";
  public search2 = "";
  public log = [];

  stringify(item) {
    return JSON.stringify(item);
  }
  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: Horizon AI Chat Assistant Report";
    } else {
      document.title = "Horizon AI Chat Assistant Report";
    }
  }
  public async mounted() {
    const data = await dispatchBotlogReport(this.$store, {});
    this.log = _.get(data, "data.data.log", []);
  }

  diffDates(date1, date2) {
    return moment(date2).diff(moment(date1), "days");
  }
  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  getPmethod(item) {
    if (item.billing) {
      return item.billing.ptype;
    }
    if (item.rebill) {
      return item.rebill.ptype;
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Horizon AI Chat Assistant Report ")]),_c('v-spacer')],1),_c('v-card',[_c('br'),_c('div',{staticStyle:{"margin-left":"auto","text-align":"right"}},[_c('JsonCSV',{staticClass:"btn btn-default",attrs:{"name":"chat_assistant.csv","data":_vm.userCSV}},[_c('v-btn',[_vm._v("Export CSV")])],1)],1),_c('br'),_c('v-layout',{attrs:{"align-center":"","justify-center":""}}),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.log,"search":_vm.search,"multi-sort":"","loading-text":"Loading... Please wait","options":{
        itemsPerPage: 1000,
      },"items-per-page":1000,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 1000],
      }},scopedSlots:_vm._u([{key:"item.created_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_date))+" ")]}},{key:"item.updated_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_date))+" ")]}}])})],1),_c('AdminViewUser',{attrs:{"user_id":_vm.user_id,"scroll_item":_vm.scroll_item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }